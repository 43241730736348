// import riddles from "./DataBank";
import {useEffect, useState} from "react";

function App() {
    const [riddles, setRiddles] = useState(null);

    const [currentRiddle, setCurrentiddle] = useState(0)
    const [allowRiddles, setAllowRiddles] = useState(false)
    const [hintOpened, setHintOpened] = useState(false)
    const [inventoryOpened, setInventoryOpened] = useState(false)

    const [errStatus, setErrStatus] = useState(0)
    const [currentAnswer, setCurrentAnswer] = useState("")
    const [showNextButton, setShowNextButton] = useState(false)

    useEffect(() => {
        if (errStatus !== 0) {
            setTimeout(() => {
                setErrStatus(0)
            }, 1000)
        }
    }, [errStatus])

    useEffect(() => {
        fetch('https://be.neoquest.net/api/quest/episode/335/riddle')
            .then(response => response.json())
            .then(data => {
                setRiddles(data.data)
                console.log(data.data)
            });

    },[])

    function handleAccess(val) {
        if (val) {
            setAllowRiddles(true)
        } else {
            setAllowRiddles(false)
        }
    }

    function checkAnswer() {
        console.log("checking answer: " + currentAnswer)
        if (currentAnswer.toLowerCase() === riddles[currentRiddle].solution.toLowerCase()) {
            setErrStatus(2)
            if (riddles.length !== currentRiddle + 1) {
                setShowNextButton(true)
            }
        } else {
            setErrStatus(1)
        }

    }

    function handleNext() {
        if (riddles.length !== currentRiddle + 1) {
            setCurrentiddle(current => current + 1)
            setShowNextButton(false)
            setCurrentAnswer("")
        } else {
            alert("All riddles solved!")
            setShowNextButton(false)
        }
    }


    if (allowRiddles) {
        return (
            <div className="App Riddle_screen">
                {riddles && riddles.length > 0 &&
                    <div className={"riddle_single"}
                         style={{backgroundImage: `url(https://be.neoquest.net/storage/app/public/${riddles[currentRiddle].main_image.replaceAll(" ", "%20")})`}}
                    >
                        <div className="riddle_section">
                            <div className="riddle_text">
                                {
                                    showNextButton &&
                                    <div className={"next_button"}>
                                        <button onClick={handleNext}>NEXT</button>
                                    </div>
                                }

                                <h4>{riddles[currentRiddle].name}</h4>
                                <p>{riddles[currentRiddle].description}</p>
                            </div>

                            <div className="riddle_answer">
                                <div>
                                    <div className={"help"}>
                                        <button onClick={() => {
                                            setInventoryOpened(false)
                                            setHintOpened(current => !current)
                                        }}
                                                className={hintOpened ? "opened" : ""}
                                        >HINT
                                        </button>
                                    </div>
                                    <div className={"help"}>
                                        <button onClick={() => {
                                            setHintOpened(false)
                                            setInventoryOpened(current => !current)
                                        }}
                                                className={inventoryOpened ? "opened" : ""}
                                        >INVENTORY
                                        </button>
                                    </div>
                                    <div className={"input-group"}>
                                        <input type="text" placeholder={"Type your answer..."}
                                               onChange={(event) => {
                                                   setCurrentAnswer(event.target.value)
                                               }}
                                               value={currentAnswer}/>
                                        <button onClick={checkAnswer}
                                                className={errStatus === 1 ? "flash_error" : errStatus === 2 ? "flash_success" : ""}
                                        >Answer
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"hint_section " + (hintOpened ? "opened" : '')}>
                            <p>{riddles[currentRiddle].hint}</p>
                        </div>

                        <div className={"hint_section inventory " + (inventoryOpened ? "opened" : '')}>
                            <div className={"video"}>
                                <p>Watch Video</p>
                                {/*<video src="https://via.placeholder.com/video" controls/>*/}
                            </div>
                        </div>

                        <div className="audio-player">
                            <audio src={`https://be.neoquest.net/storage/app/public/${riddles[currentRiddle].audio_content.replaceAll(" ", "%20")}`} autoPlay/>
                        </div>

                    </div>
                }
            </div>

        );

    } else {
        return (
            <div className="Landing">
                <button onClick={() => handleAccess(true)}>Start To Play</button>
            </div>
        );
    }

}

export default App;
